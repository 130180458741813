import useStatusLogList from "./hooks/useStatusLogList";
import "./css/statusLogList.scss";

const StatusLogList = () => {
  const hook = useStatusLogList();
  return (
    <div>
      {false ? (
        <p>Loading ...</p>
      ) : (
        <div id="statusLogList">
          <div id="header">
            <div>
              <span>오류 기기 발생시 담당자 연락 바랍니다:</span>
              <span>addd 김현빈 팀장 010-5128-2602</span>
            </div>
            <div>
              <div>
                <span>총 {hook.list.length}개</span>
                <span>
                  (
                  <span className="color-0">
                    {hook.list.reduce((n, item: any) => {
                      console.log(item.c);
                      return n + (item.check === "0" ? 1 : 0);
                    }, 0)}
                  </span>
                  &nbsp;/&nbsp;
                  <span className="color-1">
                    {hook.list.reduce((n, item: any) => {
                      console.log(item.c);
                      return n + (item.check === "1" ? 1 : 0);
                    }, 0)}
                  </span>
                  )
                </span>
              </div>
              <div>
                <div>
                  <select
                    value={hook.date}
                    onChange={(e) => {
                      hook.setDate(Number(e.target.value));
                    }}>
                    <option value="1">오늘</option>
                    <option value="7">일주일</option>
                    <option value="0">전체</option>
                  </select>
                </div>
                <div>
                  <select
                    value={hook.type2}
                    onChange={(e) => {
                      hook.setType2(e.target.value);
                    }}>
                    <option value="0">최초 설치</option>
                    <option value="1">부팅 전체</option>
                  </select>
                </div>
                <div>
                  <select
                    value={hook.type1}
                    onChange={(e) => {
                      hook.setType1(e.target.value);
                    }}>
                    <option value="main">메인</option>
                    <option value="test">테스트</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div>
                  <div>최근 갱신 | {hook.reloadTime}</div>
                  <div>현재 페이지는 위 갱신 시점의 데이터입니다.</div>
                </div>
                <div>
                  <button
                    onClick={() =>
                      hook.fetchData(hook.date, hook.type1, hook.type2)
                    }>
                    새로고침
                  </button>
                  <button onClick={hook.downloadExcel}>엑셀 다운로드</button>
                </div>
              </div>
              {hook.type2 === "0" && (
                <div>
                  <div>최초 설치 기기</div>
                  <div>
                    <div>
                      <span>오늘</span>
                      <div>{hook.countText[0]}대</div>
                    </div>
                    <div>
                      <span>{hook.countText[1]}시</span>
                      <div>{hook.countText[2]}대</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div id="body">
            {hook.list.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <div>
                    <div>
                      <div>{item.countText}</div>
                      <div>{item.mac}</div>
                      <div>ID : {item.id}</div>
                      <div className="hovertext" data-hover={item.status}>
                        <div className="hover-text">STATUS : {item.status}</div>
                      </div>
                      {/* <p>날짜 : {hook.dateFormat(item.create_date)}</p> */}
                    </div>
                    <div>
                      {item.check == "1" ? (
                        <div className="color-1">점검 필요</div>
                      ) : (
                        <div className="color-0">정상</div>
                      )}
                      <div
                        className="hovertext"
                        data-hover={hook.dateFormatSecond(item.create_date)}>
                        {hook.dateFormat(item.create_date)}
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        hook.getDetailData(item.id, item.mac, index);
                      }}>
                      <span>{item.detail ? "-" : "+"}</span>
                    </div>
                  </div>
                  {item.detail && (
                    <div>
                      <div>
                        <div>날짜</div>
                        <div>메인 / 테스트</div>
                        <div>상태</div>
                      </div>
                      {item.detail.map((detail: any, index2: number) => {
                        return (
                          <div key={index2}>
                            <div>{hook.dateFormat(detail.createDate)}</div>
                            <div>{detail.test ? "TEST" : "MAIN"}</div>
                            <div
                              className="hovertext"
                              data-hover={detail.status}>
                              <div className="hover-text">{detail.status}</div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default StatusLogList;
