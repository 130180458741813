import axios from "axios";
import { useEffect, useState } from "react";

const useStatusLogList = () => {
  const [date, setDate] = useState(1);
  const [type1, setType1] = useState("main");
  const [type2, setType2] = useState("0");
  const [list, setList] = useState([]);
  const [detailList, setDetailList] = useState({});
  const [countText, setCountText] = useState([0, 0, 0]);
  const [reloadTime, setReloadTime] = useState("");
  const [autoReload, setAutoReload] = useState(false);

  const fetchData = async (date: number, type1: string, type2: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/data/statusLogList?date=${date}&type1=${type1}&type2=${type2}`
      );

      const jsonData = await response.json();
      setList(createTimeData(jsonData));
      setReloadTime(dateFormatSecond(new Date()));
    } catch (error) {
      setList(createTimeData([]));
      setReloadTime(dateFormatSecond(new Date()));
    }
  };

  const getDetailData = async (id: string, mac: string, index: number) => {
    if (list[index]["detail"]) {
      const copy_list = JSON.parse(JSON.stringify(list));
      delete copy_list[index]["detail"];

      setList(copy_list);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/data/statusLogDetail?id=${id}&mac=${mac}`
      );

      const jsonData = await response.json();

      const copy_list = JSON.parse(JSON.stringify(list));

      const copy_detail_list = JSON.parse(JSON.stringify(detailList));
      copy_detail_list[`${id}-${mac}`] = jsonData;

      copy_list[index]["detail"] = jsonData;
      setList(copy_list);
    } catch (error) {
      setList([]);
    }
  };

  const downloadExcel = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/data/downloadStatusLog`,
        {},
        {
          responseType: "blob",
        }
      );

      // 파일 다운로드를 위한 Blob 생성
      const blob = new Blob([response.data], { type: "text/xlsx" });
      const url = window.URL.createObjectURL(blob);

      // 다운로드 링크 생성 및 클릭
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "statusLog.csv");
      document.body.appendChild(link);
      link.click();

      // 생성된 URL 해제
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error creating xlsx:", error);
    }
  };

  const createTimeData = (array: any) => {
    array.reverse();
    const times: any = {};
    array.map((item: any) => {
      var date = dateFormat(item.create_date).split(" ")[0];
      var hour = new Date(item.create_date).getHours();

      if (!times[date]) {
        times[date] = {
          count: 1,
          [hour]: 1,
        };
      } else {
        times[date]["count"]++;
        if (!times[date][hour]) {
          times[date][hour] = 0;
        }
        times[date][hour]++;
      }

      item.countText = `${date} - ${times[date]["count"]} | ${hour}시 - ${times[date][hour]}`;

      return item;
    });

    if (times["오늘"]) {
      var a = Object.keys(times["오늘"]);
      setCountText([
        times["오늘"]["count"],
        a[a.length - 2],
        times["오늘"][a[a.length - 2]],
      ]);
    } else {
      setCountText([0, 0, 0]);
    }

    return array.reverse();
  };

  const dateFormat = (date: string) => {
    const now = new Date();
    const a = new Date(date);

    const y = a.getFullYear();
    const m = String(a.getMonth() + 1).padStart(2, "0");
    const d = String(a.getDate()).padStart(2, "0");
    const h = String(a.getHours()).padStart(2, "0");
    const i = String(a.getMinutes()).padStart(2, "0");
    // const s = String(a.getSeconds()).padStart(2, "0");
    if (
      now.getTime() - a.getTime() < 86400000 &&
      now.getDate() === a.getDate()
    ) {
      return `오늘 ${h}:${i}`;
    }
    return `${y}-${m}-${d} ${h}:${i}`;
  };

  const dateFormatSecond = (date: any) => {
    const a = new Date(date);

    const y = a.getFullYear();
    const m = String(a.getMonth() + 1).padStart(2, "0");
    const d = String(a.getDate()).padStart(2, "0");
    const h = String(a.getHours()).padStart(2, "0");
    const i = String(a.getMinutes()).padStart(2, "0");
    const s = String(a.getSeconds()).padStart(2, "0");

    return `${y}-${m}-${d} ${h}:${i}:${s}`;
  };

  // useEffect(() => {
  //   fetchData(date, type1, type2);
  // }, [date, type1, type2]);

  useEffect(() => {
    if (date === 1) {
      setAutoReload(true);
    } else {
      setAutoReload(false);
    }
  }, [date]);

  useEffect(() => {
    fetchData(date, type1, type2);
    if (!autoReload) return;
    const timer = setInterval(() => {
      fetchData(date, type1, type2);
    }, 5000);

    return () => clearInterval(timer);
  }, [autoReload, date, type1, type2]);

  return {
    date,
    setDate,
    type1,
    setType1,
    type2,
    setType2,
    list,
    getDetailData,
    dateFormat,
    dateFormatSecond,
    fetchData,
    reloadTime,
    countText,
    downloadExcel,
  };
};

export default useStatusLogList;
