import React from "react";
import "./css/SidebarItem.scss";
import { NavLink } from "react-router-dom";

interface SidebarItemProps {
  menu: {
    name: string;
    path: string;
    auth?: string[];
  };
  active?: string;
  userGroup: string;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  menu,
  active,
  userGroup,
}) => {
  if (menu.auth && !menu.auth.includes(userGroup)) return <div></div>;
  return (
    <div className={`sidebar-item ${active}`}>
      <div>
        <p>{menu.name}</p>
      </div>
    </div>
  );
};

export default SidebarItem;
