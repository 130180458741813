import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SidebarItem from "./SidebarItem";
import "./css/Sidebar.scss";
import fetchProfile from "../../../api/userProfile";

interface Menu {
  name: string;
  path: string;
  submenu?: Menu[];
  auth?: string[];
}

function Sidebar() {
  const [expandedMenu, setExpandedMenu] = useState<string | null>(null);

  const [userGroup, setUserGroup] = useState<any>(null);

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    const profileData = await fetchProfile();
    if (profileData.status === 200) {
      setUserGroup(profileData.data.userGroup);
    }
  };

  const menus: Menu[] = [
    {
      name: "광고",
      path: "/adComponent/ad/adList",
      submenu: [
        { name: "광고", path: "/adComponent/ad/adList" },
        {
          name: "광고 사업 분류",
          path: "/adComponent/adCorpField/adCorpFieldList",
        },
        { name: "광고 분류", path: "/adComponent/adField/adFieldList" },
        { name: "광고 정보", path: "/adComponent/adVideoInfo/adVideoInfoList" },
        { name: "광고주", path: "/adComponent/advertiser/advertiserList" },
      ],
    },
    {
      name: "매체",
      path: "/mediaComponent/media/list",
      submenu: [
        { name: "매체", path: "/mediaComponent/media/list" },
        {
          name: "매체 기기",
          path: "/mediaComponent/mediaDevice/mediaDeviceList",
        },
        {
          name: "매체 네트워크",
          path: "/mediaComponent/mediaNetwork/mediaNetworkList",
        },
        {
          name: "매체 좌표",
          path: "/mediaComponent/mediaPoint/list",
        },
      ],
    },
    { name: "비즈니스", path: "/businessType/businessTypeList" },
    { name: "CPV", path: "/cpv/cpvList" },
    {
      name: "장소",
      path: "/siteComponent/site/list",
      submenu: [
        { name: "장소", path: "/siteComponent/site/list" },
        { name: "장소 분류", path: "/siteComponent/siteField/siteFieldlist" },
      ],
    },
    {
      name: "AI 기기",
      path: "/aiComponent/aiDevice/aiDeviceList",
      submenu: [
        { name: "AI 기기", path: "/aiComponent/aiDevice/aiDeviceList" },
        {
          name: "AI 기기 종류",
          path: "/aiComponent/aiDeviceType/aiDeviceTypeList",
        },
        {
          name: "AI 기기 이슈",
          path: "/aiComponent/aiDeviceIssue/aiDeviceIssueList",
        },
        {
          name: "AI 기기 업체",
          path: "/aiComponent/aiDeviceCompany/aiDeviceCompanyList",
        },
        {
          name: "AI 모듈",
          path: "/aiComponent/aiModule/aiModuleList",
        },
      ],
    },
    {
      name: "카메라",
      path: "/cameraComponent/camera/cameraList",
      submenu: [
        { name: "카메라", path: "/cameraComponent/camera/cameraList" },
        {
          name: "카메라 종류",
          path: "/cameraComponent/cameraType/cameraTypeList",
        },
      ],
    },
    {
      name: "데이터",
      path: "/effectComponent/dailyEffect/dailyEffectList",
      submenu: [
        {
          name: "일간 데이터",
          path: "/effectComponent/dailyEffect/dailyEffectList",
        },
        {
          name: "일간 Real 데이터",
          path: "/effectComponent/dailyRealEffect/dailyRealEffectList",
        },
        {
          name: "시간 데이터",
          path: "/effectComponent/hourlyEffect/hourlyEffectList",
        },
        {
          name: "시간 Real 데이터",
          path: "/effectComponent/hourlyRealEffect/hourlyRealEffectList",
        },
        {
          name: "매체 데이터",
          path: "/effectComponent/mediaEffect/mediaEffectList",
        },
        {
          name: "광고 데이터",
          path: "/effectComponent/adlyEffect/adlyEffectList",
        },
      ],
    },
    {
      name: "그래프",
      path: "/graphComponent/lineGraph/lineGraphList",
      submenu: [
        {
          name: "일간 Real 데이터 그래프",
          path: "/graphComponent/dailyRealEffectGraphs/dailyRealEffectGraphsList",
        },
      ],
    },
    {
      name: "네트워크",
      path: "/networkComponent/networkDevice/networkDeviceList",
      submenu: [
        {
          name: "네트워크 기기",
          path: "/networkComponent/networkDevice/networkDeviceList",
        },
        {
          name: "네트워크 기기 종류",
          path: "/networkComponent/networkDeviceType/networkDeviceTypeList",
        },
      ],
    },
    {
      name: "관제",
      path: "/dashboard",
      submenu: [
        { name: "일간 데이터 관제", path: "/dashboard/daily" },
        { name: "주간 데이터 관제", path: "/dashboard/weekly" },
        { name: "광고 다운로드 관제", path: "/dashboard/ad" },
        { name: "스케줄러 관제", path: "/dashboard/schedule" },
      ],
    },
    {
      name: "달고T 관제",
      path: "/dalgoT",
      submenu: [
        { name: "월간 데이터 관제", path: "/dalgoT/monthly" },
        { name: "일간 데이터 관제", path: "/dalgoT/daily" },
        { name: "경로 관리", path: "/dalgoT/path" },
        { name: "매체 관제", path: "/dalgoT/media" },
      ],
    },
    {
      name: "AL 관제",
      path: "/adddiLight",
      submenu: [
        { name: "실시간 기기 등록", path: "/adddiLight/registrationList" },
      ],
    },
    { name: "설치 문서", path: "/installReport/installReportList" },
    {
      name: "버전관리",
      path: "/versionManager",
      submenu: [
        { name: "폴더 불러오기", path: "/versionManager/folder" },
        { name: "매체별 히스토리", path: "/versionManager/history" },
        {
          name: "업데이트 파일 관리",
          path: "/versionManager/update",
          auth: ["admin", "ai"],
        },
      ],
    },
    {
      name: "파일 조회",
      path: "/inquiry",
      submenu: [
        { name: "LOG 조회", path: "/inquiry/logInquiryList" },
        { name: "CSV 조회", path: "/inquiry/csvInquiryList" },
      ],
    },
  ];

  const handleMenuClick = (path: string, hasSubmenu: boolean) => {
    if (hasSubmenu) {
      setExpandedMenu(expandedMenu === path ? null : path);
    }
  };

  const [activeMenu, setActiveMenu] = useState<string>("");
  return (
    <div className={`sidebar ${expandedMenu ? "expanded" : "collapsed"}`}>
      {menus.map((menu, index) => (
        <div key={index}>
          {menu.submenu ? (
            <div>
              <div onClick={() => handleMenuClick(menu.path, true)}>
                <SidebarItem menu={menu} userGroup={userGroup} />
              </div>
              {expandedMenu === menu.path && (
                <div className="submenu">
                  {menu.submenu.map((subItem, subIndex) => (
                    <Link
                      to={subItem.path}
                      key={subIndex}
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveMenu(subItem.path);
                      }}>
                      <SidebarItem
                        menu={subItem}
                        active={subItem.path === activeMenu ? "active" : ""}
                        userGroup={userGroup}
                      />
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <Link
              to={menu.path}
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                setActiveMenu(menu.path);
              }}>
              <SidebarItem
                menu={menu}
                active={menu.path === activeMenu ? "active" : ""}
                userGroup={userGroup}
              />
            </Link>
          )}
        </div>
      ))}
    </div>
  );
}

export default Sidebar;
