import axios from "axios";
import { useEffect, useState } from "react";

interface hookMember {
  serviceList: string[];
  service: string;
  setService: any;
  type: string;
  setType: any;
  data: any;
  changeStatus: any;
  download: any;
  modalIsOpen: boolean;
  setModalIsOpen: any;
  input: string;
  setInput: any;
  saveService: any;
  deleteFile: any;
  typeList: string[];
  updateHistory: any;
}

const useDalgoTMediaDashboard = (): hookMember => {
  const [serviceList, setServiceList] = useState<any>([]);
  const [service, setService] = useState<string>("");
  const [type, setType] = useState<string>("APP");
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [input, setInput] = useState<any>([]);
  const [typeList, setTypeList] = useState<string[]>([]);

  const getService = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/update/serviceList`,
        {
          headers: {
            key: "$2b$10$oEqDXe.5Sq6Vm2dUYnMxfuXDm2Z9CPG9oDA3GjEVitVhiaZzdb.v.",
          },
        }
      );
      const jsonData = await response.json();

      if (jsonData) {
        const array = Object.keys(jsonData);
        setServiceList(jsonData);
        if (!service) setService(array[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getData = async (service: string, type: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/update/fileList?service=${service}&type=${type}`,
        {
          headers: {
            key: "$2b$10$oEqDXe.5Sq6Vm2dUYnMxfuXDm2Z9CPG9oDA3GjEVitVhiaZzdb.v.",
          },
        }
      );
      const jsonData = await response.json();

      if (!jsonData) {
        setData([]);
      } else {
        setData(jsonData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 배포, 철회
  const changeStatus = async (filepath: string, test: boolean) => {
    const text = test
      ? "배포 상태로 변경하겠습니까?"
      : "테스트 상태로 변경하겠습니까?";
    if (window.confirm(text)) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/update/changeStatus`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              key: "$2b$10$oEqDXe.5Sq6Vm2dUYnMxfuXDm2Z9CPG9oDA3GjEVitVhiaZzdb.v.",
            },
            body: JSON.stringify({
              filepath: filepath,
            }),
          }
        );
        const jsonData = await response.json();
        if (jsonData) {
          alert(jsonData.message);
          getData(service, type);
        } else {
        }
      } catch (error) {
        console.error(error);
      }
    } else {
    }
  };

  // 삭제
  const deleteFile = async (filepath: string) => {
    if (window.confirm("삭제하겠습니까?")) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/update/delete`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              key: "$2b$10$oEqDXe.5Sq6Vm2dUYnMxfuXDm2Z9CPG9oDA3GjEVitVhiaZzdb.v.",
            },
            body: JSON.stringify({
              filepath: filepath,
            }),
          }
        );
        const jsonData = await response.json();
        if (jsonData.status === 200) {
          alert(jsonData.message);
          getData(service, type);
        } else {
          alert(jsonData.message);
          console.log(jsonData);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
    }
  };

  // 다운로드
  const download = async (path: string) => {
    alert("다운로드가 진행 중입니다.");
    const fileBit = path.split("/");
    const fileName = fileBit[fileBit.length - 1];
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/update/downloadOne`,
        { path },
        {
          responseType: "blob",
          headers: {
            key: "$2b$10$oEqDXe.5Sq6Vm2dUYnMxfuXDm2Z9CPG9oDA3GjEVitVhiaZzdb.v.",
          },
        }
      );

      // 파일 다운로드를 위한 Blob 생성
      const blob = new Blob([response.data], { type: "text/xlsx" });
      const url = window.URL.createObjectURL(blob);

      // 다운로드 링크 생성 및 클릭
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // 생성된 URL 해제
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error Download:", error);
    }
  };

  const saveService = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/update/saveService`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            key: "$2b$10$oEqDXe.5Sq6Vm2dUYnMxfuXDm2Z9CPG9oDA3GjEVitVhiaZzdb.v.",
          },
          body: JSON.stringify({
            service: input,
          }),
        }
      );
      const jsonData = await response.json();
      if (jsonData.status === 200) {
        getService();
        alert(jsonData.message);
      } else if (jsonData.status === 400) {
        alert(jsonData.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateHistory = async (filepath: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}/update/updateHistory?path=${filepath}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            key: "$2b$10$oEqDXe.5Sq6Vm2dUYnMxfuXDm2Z9CPG9oDA3GjEVitVhiaZzdb.v.",
          },
        }
      );
      const jsonData = await response.json();
      console.log(jsonData);
      // if (jsonData.status === 200) {
      //   alert(jsonData.message);
      //   getData(service, type);
      // } else {
      //   alert(jsonData.message);
      //   console.log(jsonData);
      // }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!service || !type) return;
    getData(service, type);
  }, [service, type]);

  useEffect(() => {
    getService();
  }, []);

  useEffect(() => {
    if (!service) return;
    if (serviceList[service].length > 0) {
      setTypeList(serviceList[service]);
    } else {
      setTypeList(["APP", "FIRMWARE"]);
    }
  }, [service]);

  useEffect(() => {
    if (!typeList.includes(type)) {
      setType(typeList[0]);
    }
  }, [typeList]);

  return {
    serviceList,
    service,
    setService,
    type,
    setType,
    data,
    changeStatus,
    download,
    modalIsOpen,
    setModalIsOpen,
    input,
    setInput,
    saveService,
    deleteFile,
    typeList,
    updateHistory,
  };
};

export default useDalgoTMediaDashboard;
