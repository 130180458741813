import useFileVersionList from "./hooks/useFileVersionList";
import "./css/useFileVersionList.scss";
import { useEffect, useState } from "react";
import fetchProfile from "../../../api/userProfile";
import { useNavigate } from "react-router-dom";

const FileVersionList: any = () => {
  const hook = useFileVersionList();
  const navigate = useNavigate();

  const [profile, setProfile] = useState<any>(null);

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    const profileData = await fetchProfile();
    if (
      profileData.data.userGroup === "admin" ||
      profileData.data.userGroup === "ai"
    ) {
      setProfile(profileData);
    } else {
      navigate("/");
    }
  };

  return (
    <div id="version">
      {hook.modalIsOpen ? (
        <div id="modal">
          <div>
            <button onClick={() => hook.setModalIsOpen(false)}>X</button>
          </div>
          <div>
            <input
              type="text"
              value={hook.input}
              onInput={(e: any) => {
                if (/[a-zA-Z]/.test(e.nativeEvent.data)) {
                  hook.setInput(e.target.value.toUpperCase());
                }
              }}
            />
            <button onClick={() => hook.saveService()}>추가</button>
          </div>
          <div>
            {Object.keys(hook.serviceList).map((service, index) => {
              return <option key={index}>{service}</option>;
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
      <h1 id="title">업데이트 파일 관리</h1>
      <div id="option">
        <div>
          <select
            value={hook.service}
            onChange={(e) => hook.setService(e.target.value)}>
            {Object.keys(hook.serviceList).map((service, index) => {
              return (
                <option value={service} key={index}>
                  {service}
                </option>
              );
            })}
          </select>
          <button onClick={() => hook.setModalIsOpen(true)}>+</button>
        </div>
        <div>
          <select
            value={hook.type}
            onChange={(e) => hook.setType(e.target.value)}>
            {hook.typeList.map((type, index) => {
              return (
                <option value={type} key={index}>
                  {type}
                </option>
              );
            })}
            {/* <option value="APP">APP</option> */}
            {/* <option value="FIRMWARE">FIRMWARE</option> */}
          </select>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <td>파일명</td>
            <td>상태</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {hook.data.map((item: any, index: number) => {
            return (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.test ? "TEST" : "MAIN"}</td>
                <td>
                  <button onClick={() => hook.download(item.path)}>
                    다운로드
                  </button>
                  <button
                    onClick={() => hook.changeStatus(item.path, item.test)}>
                    {item.test ? "배포" : "철회"}
                  </button>
                  {item.test ? (
                    <button onClick={() => hook.deleteFile(item.path)}>
                      삭제
                    </button>
                  ) : (
                    <button onClick={() => hook.updateHistory(item.path)}>
                      기록
                    </button>
                  )}
                  {/* <button
                    disabled={item.test ? false : true}
                    onClick={() => hook.deleteFile(item.path)}>
                    삭제
                  </button> */}
                </td>
              </tr>
            );
          })}
          {hook.data.length == 0 && (
            <tr>
              <td colSpan={3}>파일이 없습니다.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default FileVersionList;
